import {mapMutations} from "vuex";
import mainButton from '@/components/main-button/index.vue';
//sections
export default {
  name: "success-registration",
  components:{
    mainButton,
  },
  data(){
    return{
      see: false,
    }
  },
  created() {

  },
  computed:{

  },

  methods:{
    ...mapMutations({
    }),

  }
}